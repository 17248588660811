import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Socket } from 'socket.io-client';
import style from './controls.module.scss';

type SnapshotProps = {
  socket: Socket | null;
};

export const Snapshot: FC<SnapshotProps> = ({ socket }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'meeting' });

  const handleClick = () => {
    if (!socket) return;
    socket.emit('snapshot');
  };

  return (
    <>
      <button className={cn(style.button, style.button__snapshot)} title={t('controls.snapshot')} onClick={handleClick}>
        Snapshot
      </button>
    </>
  );
};
