import React, { FC } from 'react';
import cn from 'classnames';
import { JanusJS } from 'janus-gateway';
import { Socket } from 'socket.io-client';
import { useGetWindowSize } from 'hooks';
import { RemoteStreams } from 'hooks/use-janus';
import { useGetVisibleCameraControls } from 'pages/meeting';
import { OtherContent } from 'pages/meeting/constants';
import { useKeyboardEvents } from 'pages/meeting/hooks';
import {
  Exit,
  Microphone,
  Flashlight,
  Laser,
  Fix,
  VideoFurther,
  Snapshot,
  Options,
  Chat,
  People,
  Fullscreen,
  Video,
  VisualEffects,
  Translation,
  PhoneHeadsetTakeSnapshot,
  VideoResolution,
  Position,
} from './controls';
import style from './controls-panel.module.scss';

type ControlsPanelProps = {
  socket: Socket | null;
  stream: MediaStream | null;
  remoteStreams: RemoteStreams;
  selectStreamId: string;
  pluginHandle: JanusJS.PluginHandle | null;
  typeOtherContent: OtherContent | null;
  isFullscreen: boolean;
  isHasAccessMicrophone: boolean;
  isHasAccessVideo: boolean;
  isActiveMicrophone: boolean;
  isActiveVideo: boolean;
  isShowVisualEffects: boolean;
  isTranslation: boolean;
  roomElement: HTMLDivElement | null;
  setIsTranslation: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsShowVisualEffects: React.Dispatch<React.SetStateAction<boolean>>;
  setIsTypeOtherContent: React.Dispatch<React.SetStateAction<OtherContent | null>>;
  setIsActiveMeeting: React.Dispatch<React.SetStateAction<boolean>>;
  setPause: React.Dispatch<React.SetStateAction<boolean>>;
  setIsActiveMicrophone: React.Dispatch<React.SetStateAction<boolean>>;
  setIsActiveVideo: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ControlsPanel: FC<ControlsPanelProps> = ({
  socket,
  stream,
  remoteStreams,
  selectStreamId,
  pluginHandle,
  typeOtherContent,
  isFullscreen,
  isHasAccessMicrophone,
  isHasAccessVideo,
  isActiveMicrophone,
  isActiveVideo,
  isShowVisualEffects,
  isTranslation,
  roomElement,
  setIsTranslation,
  setIsFullScreen,
  setIsShowVisualEffects,
  setIsTypeOtherContent,
  setIsActiveMeeting,
  setPause,
  setIsActiveMicrophone,
  setIsActiveVideo,
}) => {
  const { isMobile } = useGetWindowSize();
  const { isVisibleCameraControls, isMyHeadset, isVersionFirst, isUserHeadset } = useGetVisibleCameraControls({
    myStream: stream,
    remoteStreams,
    selectStreamId,
  });
  useKeyboardEvents(socket, isVisibleCameraControls);

  return (
    <div className={cn(style.block, { [style.block__fullscreen]: isFullscreen })}>
      <div className={style.container}>
        {isMyHeadset && <PhoneHeadsetTakeSnapshot socket={socket} stream={stream} />}
        <Exit setIsActiveMeeting={setIsActiveMeeting} setPause={setPause} />
        <Microphone
          isActive={isActiveMicrophone}
          isHasAccess={isHasAccessMicrophone}
          setIsActive={setIsActiveMicrophone}
        />
        <Video isActive={isActiveVideo} isHasAccess={isHasAccessVideo} setIsActive={setIsActiveVideo} />
        <Translation isTranslation={isTranslation} setIsTranslation={setIsTranslation} />
        {isVisibleCameraControls && (
          <>
            {!isVersionFirst && !isUserHeadset && (
              <>
                <Flashlight socket={socket} />
                <Laser socket={socket} />
                <Fix socket={socket} />
                <VideoFurther socket={socket} />
              </>
            )}
            {!isMobile && (
              <>
                {!isUserHeadset && <Position socket={socket} />}
                <VideoResolution socket={socket} />
              </>
            )}
            {!isMyHeadset && <Snapshot socket={socket} />}
            <VisualEffects isShowVisualEffects={isShowVisualEffects} setIsShowVisualEffects={setIsShowVisualEffects} />
          </>
        )}
        <Fullscreen element={roomElement} isFullscreen={isFullscreen} setIsFullScreen={setIsFullScreen} />
        <Chat typeOtherContent={typeOtherContent} setIsTypeOtherContent={setIsTypeOtherContent} />
        <People typeOtherContent={typeOtherContent} setIsTypeOtherContent={setIsTypeOtherContent} />
        {isVisibleCameraControls && !isVersionFirst && !isUserHeadset && <Options socket={socket} />}
      </div>
    </div>
  );
};
