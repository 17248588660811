import React, { FC, useState } from 'react';
import { Socket } from 'socket.io-client';
import { DropdownSelect } from 'components';
import style from './controls.module.scss';

const DATA_VIDEO_RESOLUTION = [
  {
    id: 'auto',
    label: 'Auto',
  },
  {
    id: 'low',
    label: 'Low',
  },
];

type VideoResolutionProps = {
  socket: Socket | null;
};

export const VideoResolution: FC<VideoResolutionProps> = ({ socket }) => {
  const [videoResolution, setVideoResolution] = useState(DATA_VIDEO_RESOLUTION[0].id);

  const handleChangeVideoResolution = (id: string) => {
    if (!socket) return;
    socket.emit('resolution_ratio', { res: id });
    setVideoResolution(id);
  };

  return (
    <div className={style.videoResolution}>
      <DropdownSelect
        isTop
        dataList={DATA_VIDEO_RESOLUTION}
        value={videoResolution}
        onChange={handleChangeVideoResolution}
      />
    </div>
  );
};
